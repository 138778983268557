import { ReactElement, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Facture, PaymentStatus, TPayment } from '@Arti-zen/package-backoffice'
import { NavLink, useParams } from 'react-router-dom'
import { Format } from 'utils/format'
import { Button } from '@mui/material'
import { ApiArtizenBO } from 'utils/artizenConnector'
import { statusPaymentProgress } from '../../store/store.facturation.helpers'
import { pages } from 'navigation/pages'
import { Theme } from 'theme/theme'
import FactureManualPaymentModal from './Facture.manualPayment.modal'
import { EPaymentProgressStatus } from 'modules/facturation/store/store.facturation.interfaces'
import { DisplayPaymentProgress } from 'components/FactureList/FactureList.definitionFields'

export default function FactureDisplay(): ReactElement {
  const [facture, setFacture] = useState<Facture>(null)
  const [payments, setPayments] = useState<TPayment[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [forceRefresh, setForceRefresh] = useState<number>(0)
  const [isPaymentEnable, setIsPaymentEnable] = useState(true)

  const { id } = useParams<{ id: string }>()

  const { linkedPayments, otherPayments } = useMemo(() => {
    return {
      linkedPayments: payments.filter((payment) => payment.factureId === id),
      otherPayments: payments.filter((payment) => payment.factureId !== id),
    }
  }, [payments])

  const totalUnPaid = useMemo(
    () =>
      linkedPayments.reduce((acc, payment) => {
        const isUnPaid = statusPaymentProgress([payment]) === EPaymentProgressStatus.unpaid

        return isUnPaid ? acc + (payment.amountInCts - payment.amountRefunded) / 100 : acc
      }, 0),
    [linkedPayments]
  )

  useEffect(() => {
    let isMount = true
    if (id) {
      ApiArtizenBO.facturation
        .getOne(id)
        .then((fact) => isMount && setFacture(fact))
        .catch(console.error)
    }

    return () => {
      isMount = false
    }
  }, [id, forceRefresh])

  useEffect(() => {
    let isMount = true

    if (facture?.clientId) {
      ApiArtizenBO.payment.getAllByArtizenId(facture.clientId).then((payments) => {
        const paymentsSorted = payments.sort((a, b) => {
          return new Date(a.chargeDate) > new Date(b.chargeDate) ? 0 : 1
        })

        isMount && setPayments(paymentsSorted ?? [])
      })
    } else {
      setPayments([])
    }

    return () => {
      isMount = false
    }
  }, [facture])

  const linkFacture = async (paymentId: TPayment['id']) => {
    await ApiArtizenBO.payment.patchOne(paymentId, { factureId: facture.id })
    setForceRefresh(forceRefresh + 1)
  }

  const unLinkFacture = async (paymentId: TPayment['id']) => {
    await ApiArtizenBO.payment.patchOne(paymentId, { factureId: null })
    setForceRefresh(forceRefresh + 1)
  }

  const createNewPayment = async () => {
    // setIsPaymentEnable(false)
    await ApiArtizenBO.facturation.createPayment(facture.id)
    setForceRefresh(forceRefresh + 1)
  }

  const submitPaymentManual = async (comment: string) => {
    // setIsPaymentEnable(false)
    await ApiArtizenBO.payment.postManualPayment({ factureId: facture.id, comment })
    setForceRefresh(forceRefresh + 1)
    setIsModalOpen(false)
  }

  if (!facture)
    return (
      <Wrapper>
        <WrapperContent>No facture</WrapperContent>
      </Wrapper>
    )

  return (
    <Wrapper>
      <WrapperContent>
        <Title>Facture {facture.internalRef}</Title>

        <WrapperInfoGene>
          <Column>
            <Info label="Ref interne" value={facture.internalRef} />
            <Info
              label="Ref saas"
              value={
                <a href={facture.pdfLink} target="_blank">
                  {facture.saasId}
                </a>
              }
            />
            <Info label="Montant TTC" value={Format.currency(Number(facture.amountTTC), 2)} />
            <Info label="Date création" value={Format.dateFr(facture.createdAt)} />
            <Info label="Payment Ref" value={facture.paymentRef} />
            <Info label="Reste à payer" value={Format.currency(totalUnPaid, 2)} />
            <div style={{ marginBottom: 20 }} />
            <Info
              label=""
              value={
                <Button disabled={!isPaymentEnable} variant="contained" onClick={createNewPayment}>
                  Générer un paiement GCL de {Format.currency(Number(facture.amountTTC), 2)}
                </Button>
              }
            />
            <div style={{ marginBottom: 20 }} />
            <Info
              label=""
              value={
                <Button disabled={!isPaymentEnable} variant="outlined" onClick={() => setIsModalOpen(true)}>
                  Générer un paiement manuel de {Format.currency(Number(facture.amountTTC), 2)}
                </Button>
              }
            />
          </Column>

          <Column>
            <Info
              label="client id"
              value={
                <NavLink to={pages.connected.artisan.profile.navigate(facture.clientId)}>{facture.clientId}</NavLink>
              }
            />
            <Info label="email" value={facture.client?.email} />
            <Info label="Company Name" value={facture.client?.companyName} />
            <Info label="Patron" value={`${facture.client?.firstName} ${facture.client?.lastName}`} />
          </Column>
        </WrapperInfoGene>

        <div style={{ marginTop: 50 }}>Payment lié à cette facture : </div>
        {linkedPayments.map((payment) => (
          <PaymentDisplay
            key={`Payment-${payment.id}`}
            payment={payment}
            linkAction={linkFacture}
            unLinkAction={unLinkFacture}
          />
        ))}

        <div style={{ marginTop: 50 }}>Autres Payments de ce client : </div>
        {otherPayments.map((payment) => (
          <PaymentDisplay
            key={`Payment-${payment.id}`}
            payment={payment}
            linkAction={linkFacture}
            unLinkAction={unLinkFacture}
          />
        ))}
      </WrapperContent>
      {isModalOpen ? (
        <FactureManualPaymentModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          submitPaymentManual={submitPaymentManual}
        />
      ) : null}
    </Wrapper>
  )
}

const Info = ({ label, value }: { label: string; value: string | ReactElement }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', height: 25 }}>
      <div style={{ display: 'flex', width: 200 }}>{label}</div>
      <div style={{ display: 'flex', flex: 1 }}>{value}</div>
    </div>
  )
}

const PaymentDisplay = (params: {
  payment: TPayment
  linkAction: (id: TPayment['id']) => void
  unLinkAction: (id: TPayment['id']) => void
}) => {
  const { payment, linkAction, unLinkAction } = params

  const LinkButton = () => (
    <Button variant="contained" onClick={() => linkAction(payment.id)}>
      Lier
    </Button>
  )
  const UnLinkButton = ({ factureRef }: { factureRef: Facture['internalRef'] }) => (
    <Button variant="outlined" onClick={() => unLinkAction(payment.id)}>
      Délier ({factureRef})
    </Button>
  )

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 50, textAlign: 'center', alignItems: 'center' }}>
      <NavLink to={pages.connected.factureList.payment.navigate(payment.id)}>{payment.GCLid}</NavLink>
      <div style={{ flex: 1 }}>{Format.currency(+payment.amountInCts / 100, 2)}</div>
      <div style={{ flex: 1 }}>{Format.currency(-payment.amountRefunded / 100, 2)}</div>
      <div style={{ flex: 1 }}>
        <DisplayPaymentProgress paymentProgress={statusPaymentProgress([payment])} />
      </div>
      <div style={{ flex: 1 }}>
        {payment.factureId ? <UnLinkButton factureRef={payment.facture?.internalRef} /> : <LinkButton />}
      </div>
      <div style={{ flex: 1 }}>{Format.dateFr(payment.chargeDate)}</div>
    </div>
  )
}

const Title = styled.div`
  display: flex;
  justify-content: center;
  ${Theme.font.size.xlarge};
  font-weight: 700;
  margin-bottom: 50px;
`

const WrapperInfoGene = styled.div`
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 15px;
`
